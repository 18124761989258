import React from 'react';
import {
    TabbedShowLayout,
    Tab,
    TextField,
    ImageField,
    Show,
    DateField,
    SelectField,
    EditButton,
    ReferenceManyField,
    Datagrid,
    ReferenceField,
    EmailField,
    NumberField

} from 'react-admin'

import CardActions from '@material-ui/core/CardActions';
import { UserInformationTab } from '../user/user_show'

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const PlacementShowAction = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
    </CardActions>
);

export const PlacementShow = (props) => (
    <Show actions={<PlacementShowAction />} {...props}>
        <TabbedShowLayout>
            <UserInformationTab {...props}/>
            <Tab label="contact">

            </Tab>
            <Tab label="Date">

            </Tab>
            <Tab label="Employees">

            </Tab>
        </TabbedShowLayout>
    </Show>
);