import React from 'react';
import {
    AutocompleteInput,
    CheckboxGroupInput,
    Create,
    Edit,
    DateInput,
    email,
    FormDataConsumer,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput, SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import { Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

String.prototype.replaceAt = function (index, replacement) {
    return this.substr(0, index) + replacement + this.substr(index + replacement.length);
};


export const PlacementEdit = props => {

    const validateUserCreation = (values) => {
        const errors = {};
        //('val ', values);
        return errors;
    };

    const defaultRole = '00000010000';
    const [roles, setRoles] = React.useState(defaultRole);

    const [company, setCompany] = React.useState(null);

    const validateEmail = email();

    return (
        <Edit  {...props}>
            <SimpleForm validate={validateUserCreation}>
                {/*<ReferenceInput
                label="email"
                source="email"
                reference="global_users"
                filterToQuery={searchText => ({ email: {_ilike: `%${searchText}%`}})}>
                <AutocompleteInput
                    optionText="email"
                    focusInputOnSuggestionClick={true}
                    optionValue="email"
                    setFilter={filter => console.log("shh ", filter)}
                />
            </ReferenceInput>*/}
                <TextInput source="email" validate={validateEmail}/>
                <DateInput source="end_date"/>
                <RadioButtonGroupInput source="status" choices={[
                    {
                        name: 'ACTIVE'
                    },
                    {
                        name: 'INACTIVE'
                    },
                ]} optionText="name" optionValue="name"/>
                <NumberInput source="current_salary"/>
                <DateInput source="start_date"/>
                <ReferenceInput
                    label="user" source="user"
                    reference="global_users"
                    filterToQuery={searchText => ({ email: { _ilike: `%${searchText}%` } })}>
                    <AutocompleteInput optionText="email" focusInputOnSuggestionClick={true}/>
                </ReferenceInput>
                <Typography color='primary'>Pilih company hanya bisa satu kali, jika ingin mengganti company, reload page terlebih dahulu</Typography>
                <ReferenceInput
                    label="company"
                    source="company"
                    reference="company_profiles"
                    filterToQuery={searchText => ({ legal_name: { _ilike: `%${searchText}%` } })}
                >
                    <AutocompleteInput
                        optionText="legal_name"
                        optionValue="id"
                        focusInputOnSuggestionClick={true}
                        onChange={value => setCompany(value)}
                    />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        const _company = formData.company || null;
                        //formData.company = company;
                        setCompany(formData.company);
                        if (company) {
                            return <div {...rest}>
                                <br/>
                                <ReferenceInput
                                    perPage={25}
                                    label="Organization"
                                    source="organization"
                                    reference="company_organizations"
                                    filterToQuery={searchText => ({
                                        name: { _ilike: `%${searchText}%` },
                                        company: { _eq: company }
                                    })}
                                >
                                    <AutocompleteInput
                                        optionText="name"
                                        optionValue="id"
                                    />
                                </ReferenceInput>
                                <br/>
                                <ReferenceInput
                                    label="Level"
                                    perPage={500}
                                    source="rank"
                                    filterToQuery={searchText => ({
                                        name: { _ilike: `%${searchText}%` },
                                        company: { _eq: company }
                                    })}
                                    reference="company_employee_ranks"
                                >
                                    <AutocompleteInput
                                        optionText="name"
                                        optionValue="id"
                                    />

                                </ReferenceInput>
                                <br/>
                                <ReferenceInput
                                    label="Office"
                                    perPage={500}
                                    source="office_address"
                                    filterToQuery={searchText => ({
                                        office_name: { _ilike: `%${searchText}%` },
                                        company: { _eq: company }
                                    })}
                                    reference="company_addresses"
                                >
                                    <AutocompleteInput
                                        optionText="office_name"
                                        optionValue="id"
                                    />
                                </ReferenceInput>
                                <br/>
                                <ReferenceInput
                                    label="Designation"
                                    source="position"
                                    perPage={500}
                                    reference="company_employee_positions"
                                    filterToQuery={searchText => ({
                                        name: { _ilike: `%${searchText}%` },
                                        company: { _eq: company }
                                    })}
                                >
                                    <AutocompleteInput
                                        optionText="name"
                                        optionValue="id"
                                        focusInputOnSuggestionClick={true}/>
                                </ReferenceInput>
                                <br />
                                <ReferenceInput
                                    perPage={500}
                                    label="Job Profile/Position"
                                    source="job_profile"
                                    reference="company_job_profiles"
                                    filterToQuery={searchText => ({
                                        title: { _ilike: `%${searchText}%` },
                                        company: { _eq: company }
                                    })}
                                >
                                    <AutocompleteInput
                                        optionText="title"
                                        optionValue="id"
                                        focusInputOnSuggestionClick={true}/>
                                </ReferenceInput>
                            </div>;
                        }
                    }
                    }
                </FormDataConsumer>
                <ReferenceInput
                    label="Suvervisor"
                    source="supervisor_id"
                    reference="global_users"
                    perPage={25}
                    filterToQuery={searchText => ({ email: { _ilike: `%${searchText}%` } })}>
                    <AutocompleteInput
                        optionText="email"
                        optionValue="id"/>
                </ReferenceInput>
                <TextInput source="people_fields"/>
                <DateInput source="deletedAt"/>




                <TextInput source="type" disabled/>
                <TextInput source="type_time" disabled/>
                <TextInput source="type_place" disabled/>
                <Alert severity="info">ignore error on these fields (with Read Only identifiers), it's not indicate anything,
                    because it's just Read Only!</Alert>
                <br/>



                <ReferenceInput
                    perPage={200}
                    source="__read_only_type"
                    reference="global_legal_type_placements"
                >
                    <SelectInput optionText="name"
                                 helperText="ignore error on this field, it's not indicate anything"
                                 optionValue="value"/>
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData.__read_only_type) {
                            return (
                                formData.type = formData.__read_only_type
                            );
                        }
                    }}
                </FormDataConsumer>



                <ReferenceInput
                    perPage={200}
                    source="__read_only_type_time"
                    reference="global_legal_time_placements"
                >
                    <SelectInput optionText="name"
                                 helperText="ignore error on this field, it's not indicate anything"
                                 optionValue="value"/>
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData.__read_only_type_time) {
                            return (
                                formData.type_time = formData.__read_only_type_time
                            );
                        }
                    }}
                </FormDataConsumer>




                <ReferenceInput
                    perPage={200}
                    source="__read_only_type_place"
                    reference="global_legal_place_placements"
                >
                    <SelectInput optionText="name"
                                 helperText="ignore error on this field, it's not indicate anything"
                                 optionValue="value"/>
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData.__read_only_type_place) {
                            return (
                                formData.type_place = formData.__read_only_type_place
                            );
                        }
                    }}
                </FormDataConsumer>



                <TextInput source="regno" label="Employee ID"/>
                <TextInput source="reference" label="Decree"/>

                <CheckboxGroupInput source="role" format={v => {
                    let actualVals = [];
                    if(Array.isArray(v)){
                        actualVals = v;
                    }else {
                        const parsed = v.split('');
                        parsed.forEach((r, i) => {
                            if (r === '1') {
                                actualVals.push(i + 1);
                            }
                        });
                    }
                    return actualVals;
                }}  parse={v => {
                    let defRole = '00000010000';
                    v.map(h => {
                        defRole = defRole.replaceAt(h - 1, '1');
                    });
                    return defRole;
                }} choices={[
                    {
                        id: 1,
                        name: 'Oranization Administrator'
                    },
                    {
                        id: 2,
                        name: 'Oranization Department Head'
                    },
                    {
                        id: 3,
                        name: 'Organization Finance Admin'
                    },
                    {
                        id: 4,
                        name: 'Organization HR Admin'
                    },
                    {
                        id: 5,
                        name: 'Organization HR Assistant'
                    },
                    {
                        id: 6,
                        name: 'Organization Payroll Master'
                    },
                    {
                        id: 7,
                        name: 'Organization Staff'
                    },
                    {
                        id: 8,
                        name: 'Organization Supervisor'
                    },{
                        id: 9,
                        name: 'Holding Administrator'
                    },{
                        id: 10,
                        name: 'Organization Branch Administrator'
                    },{
                        id: 11,
                        name: 'Organization WB Administrator'
                    }
                ]} optionText="name"
                />
                <TextInput source="role" disabled/>

            </SimpleForm>
        </Edit>
    );
};
