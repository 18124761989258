import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    EditButton,
    ReferenceField,
    DateField,
    NumberField,
    ImageField
} from 'react-admin';

export const BookTypes = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <ImageField source="thumbnail" />
            <ImageField source="fa_class" label="Icon"/>
            {/*<TextField source="type_fields" />
            <TextField source="deletedAt" />*/}
            <EditButton/>
        </Datagrid>
    </List>
);
