import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField, DateField} from 'react-admin';
import { ModuleNameFilter, NameFilter } from '../common/name_filter';

export const ModuleSetting = props => (
    <List filters={<ModuleNameFilter />}{...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="module_name" />

            <DateField source="date_added" />
            <DateField source="last_modified" />
            <EditButton/>
        </Datagrid>
    </List>
);
