import React from 'react';
import {
    Create, FormDataConsumer, ImageField,
    SimpleForm,
    TextInput
} from 'react-admin';


export const BankCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" label="Bank Name"/>
            <TextInput source="code" label="Bank Code"/>
            <TextInput source="swift" label="Swift Code"/>
            <TextInput source="logo" label="Logo Image URL" />
            <FormDataConsumer>
                {({
                      formData,
                      ...rest
                  }) => {
                    if (formData.logo) {
                        return (
                            <img
                                src={formData.logo}
                                alt="Bank Logo"/>
                        );
                    }

                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);
