import React from 'react';
import {
    Edit,
    SimpleForm,
    TextField,
    TextInput,
    ReferenceField,
    ImageField,
    DateInput,
    RadioButtonGroupInput,
    NumberInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Typography } from '@material-ui/core';


export const VideoEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <DateInput source="date_added" disabled/>
            <DateInput source="last_modified" disabled/>
            <ReferenceField source="owner" reference="global_users"><TextField source="email" /></ReferenceField>

            <TextInput source="title" />
            <RadioButtonGroupInput source="status" choices={[
                {
                    name: 'draft'
                },
                {
                    name: 'pending'
                },
                {
                    name: 'active'
                },
            ]} optionText="name" optionValue="name" />
            <ImageField source="thumbnail" />
            <TextInput source="thumbnail" />
            <TextInput source="subtitle" />
            <NumberInput source="pricing.IDR" label="Price in IDR"/>
            <NumberInput source="duration_seconds" />
            <TextInput source="video_url" label="JW Video ID"/>
            <RichTextInput source="summary" />
            <NumberInput source="count_content" />
            <NumberInput source="count_reviews" />
            <NumberInput source="average_rating" />
            <NumberInput source="count_discussions" />
            <Typography color='error' variant='p'>DELETE ITEM WITH CHANGE THIS FIELD TO CURRENT
                DATE</Typography>
            <DateInput source="deletedAt"/>
        </SimpleForm>
    </Edit>
);