import React from 'react';
import {
    Edit,
    SimpleForm,
    ReferenceInput,
    TextField,
    SelectInput,
    TextInput,
    ReferenceField,
    ImageField,
    DateInput,
    RadioButtonGroupInput,
    NumberInput,
    Show,
    RichTextField,
    FormDataConsumer,
    TabbedShowLayout,
    Tab
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import { Typography } from '@material-ui/core';

export const EventEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="slug" />
            <TextInput source="title" />
            <TextInput source="thumbnail" />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.thumbnail) {
                        return <img src={formData.thumbnail} style={{
                            margin: 10,
                            width: 260,
                            height: 260,
                        }} alt="Thumbnail"/>;
                    } else {
                        return <div/>;
                    }
                }}
            </FormDataConsumer>
            <ReferenceField source="creator" reference="global_users"><TextField source="email" disabled/></ReferenceField>
            <RadioButtonGroupInput source="status" choices={[
                {
                    name: 'draft'
                },
                {
                    name: 'pending'
                },
                {
                    name: 'active'
                },
            ]} optionText="name" optionValue="name" />
            <ReferenceInput source="category" reference="academy_course_categories">
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput source="type" reference="event_types">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput source="pricing.IDR" />
            <TextInput source="location_info.lat" label="Location Latitude"/>
            <TextInput source="location_info.long" label="Location Longitude"/>
            <DateInput source="datestart" />
            <DateInput source="dateend" />

            <TextInput source="summary" options={{ multiLine: true }} />
            <RichTextInput  source="description" />



            <DateInput source="last_modified" disabled/>
            <NumberInput source="count_reviews" disabled/>
            <DateInput source="date_added" disabled/>
            <NumberInput source="average_rating" disabled/>

            <Typography color='error' variant='h4'>DELETE ITEM WITH CHANGE THIS FIELD TO CURRENT
                DATE</Typography>
            <TextInput source="deletedAt"/>
            {/*<TextInput source="schedule_fields" />*/}

        </SimpleForm>
    </Edit>
);
