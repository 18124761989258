import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField, DateField} from 'react-admin';
import { NameFilter } from '../common/name_filter'

export const Provinces = props => (
    <List filters={<NameFilter />}{...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="code" />
            <TextField source="minimum_wage" />
            <EditButton/>
        </Datagrid>
    </List>
);
