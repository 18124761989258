import React from 'react';
import {
    DateInput,
    Create,
    SimpleForm,
    TextInput
} from 'react-admin';


export const FacultyCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="name" />
        </SimpleForm>
    </Create>
);
