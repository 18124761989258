import React from 'react';
import {
    ArrayField,
    Datagrid,
    EditButton,
    ImageField,
    List,
    NumberField,
    ReferenceField,
    SingleFieldList,
    TextField,
    DateField,
    BooleanField
} from 'react-admin';
import DeletedStatus from '../common/deleted_status';
import { CommonFilter } from '../common/CommonFilters';
import SimpleChipField from './SimpleChipField ';
import { makeStyles } from '@material-ui/core/styles';
import EllipField from './EllipField'

const useStyles = makeStyles({
    imgContainer: {
        '& img': {
            height: 80,
            width: 80,
            objectFit: "contain"
        }
    }
});

export const CommonList = props => {
    const classes = useStyles();
    const _sort = (props.options.schema.short) ? props.options.schema.short : {field: 'id', order: 'DESC' }
    console.log('short ', _sort)
    return (
        <List filters={<CommonFilter {...props}/>} sort={_sort} {...props}>
            <Datagrid rowClick="edit">
                {
                    props.options.schema.list.sources.map(src => {
                        switch (src.type) {
                            case 'Text':
                                return <EllipField source={src.name} key={src.name} label={src.title}/>;
                            case 'ShortText':
                                return <TextField source={src.name} key={src.name} label={src.title}/>;
                            case 'Date':
                                return <DateField source={src.name} key={src.name}/>;
                            case 'Number':
                                return <NumberField source={src.name} key={src.name}/>;
                            case 'Deleted':
                                return <DeletedStatus source={src.name} label={src.title}
                                                      key={src.name}/>;
                            case 'Boolean':
                                return <BooleanField source={src.name} key={src.name} label={src.title} />
                                    case 'Texts':
                                return <ArrayField source={src.name}>
                                    <SingleFieldList>
                                        <SimpleChipField source={src.name}/>
                                    </SingleFieldList>
                                </ArrayField>;
                            case 'Reference':
                                if (src.reference.type === 'Text') {
                                    return <ReferenceField
                                        source={src.reference.source}
                                        reference={src.reference.reference}
                                        allowEmpty>
                                        <TextField source={src.reference.target}/>
                                    </ReferenceField>;
                                }
                            case 'Image':
                                return <ImageField
                                    className={classes.imgContainer}
                                    source={src.name}
                                    label={src.title}
                                    key={src.name}/>;
                        }

                    })
                }
                <EditButton/>
            </Datagrid>
        </List>
    );
};
