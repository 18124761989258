import React from 'react';
import { List, Datagrid, TextField, EmailField, EditButton, ReferenceField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import CustomAvatar from '../CustomAvatar'
import  { UserFilter } from './user_filter';

const styles = {
    avatar: {
        width: "30px",
        heigh: "30px"
    }
};

export const UserList = withStyles(styles)(({ classes, ...props }) => (
    <List filters={<UserFilter />} {...props}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            {/*<TextField source="name" />*/}
            <ReferenceField title="name" source="id" reference="global_users">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="status" />
            <EmailField source="email" />
            <TextField source="roles" />
            <TextField source="latest_balance" />
            <CustomAvatar
                source="avatar"
            />
            <EditButton />
        </Datagrid>
    </List>
));
