import { Filter, TextInput, } from 'react-admin';
import React from 'react';

export const CommonFilter = (props) => {
    console.log("PROPS ", props);
    const resources = props.options.schema.list.filters;
    return (
        <Filter {...props}>
            {
                resources.map(source => {
                    switch (source.type) {
                        case 'Text':
                            return (
                                <TextInput
                                    label={source.title}
                                    source={(source.operator)? `${source.name}.${source.operator}` : `${source.name}._ilike`}
                                    alwaysOn
                                    parse={value => {
                                        if(source.trim) return value;
                                        if (value === '' || value === '%%' || value === '%') {
                                            return '';
                                        } else {
                                            const val = value.split('%')
                                                .join('');
                                            return `%${val}%`;
                                        }

                                    }}
                                />
                            );
                    }
                })
            }
        </Filter>
    );
};
