import React from 'react';
import {
    DateInput,
    Edit,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    SimpleForm,
    ArrayInput,
    TextInput,
    SimpleFormIterator
} from 'react-admin';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    media: {
        width: '320px',
        padding: '12px'
    },
    common: {
        display: 'block',
    }
}));

export const ModuleSettingEdit = props => {
    const classes = useStyles();
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="id" disabled/>
                <TextInput source="module_name"/>
                <TextInput source="variable_values" disabled/>

                <FormDataConsumer>
                    {({
                          formData,
                          ...rest
                      }) => {
                        // console.log(formData);
                        if (formData.module_name === 'marketplace') {
                            return (
                                <div>
                                    <ArrayInput source="variable_values.homebanner" label="Banner Data">
                                        <SimpleFormIterator>
                                            <TextInput source="link" label="Link Image"/>
                                            <FormDataConsumer>
                                                {({ formData, getSource,scopedFormData, ...rest }) => {
                                                    // console.log(scopedFormData)
                                                    if( scopedFormData && scopedFormData.link) {
                                                        return (
                                                            <img
                                                                src={scopedFormData.link}
                                                                className={classes.media}
                                                                alt={getSource('name')}/>
                                                        );
                                                    } else return <div />

                                                }}
                                            </FormDataConsumer>
                                            <TextInput source="name" label="Banner Name"/>
                                            <NumberInput source="number" label="Order number"/>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </div>
                            );
                        } else {
                            return <div/>;
                        }
                        /*if (formData.logo) {
                            return <img src={formData.logo} style={{
                                margin: 10,
                                width: 260,
                                height: 260,
                            }} alt="logo"/>;
                        } else {
                            return <div/>;
                        }*/
                    }}
                </FormDataConsumer>
                <DateInput source="date_added"/>
                <DateInput source="last_modified"/>
                <Typography color='error' variant='h4'>DELETE ITEM WITH CHANGE THIS FIELD TO CURRENT
                    DATE</Typography>
                <DateInput source="deletedAt"/>
            </SimpleForm>
        </Edit>
    );
};
