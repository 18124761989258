import React from 'react';

import {
    CheckboxGroupInput,
    Edit,
    FormDataConsumer,
    RadioButtonGroupInput,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput, TextField, ReferenceField, DateInput,
} from 'react-admin';

String.prototype.replaceAt=function(index, replacement) {
    return this.substr(0, index) + replacement+ this.substr(index + replacement.length);
}


export const CompanyEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="brand_name" />
            <TextInput source="legal_name" />
            <TextInput source="logo" />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.logo) {
                        return <img src={formData.logo} style={{
                            margin: 10,
                            width: 260,
                            height: 260,
                        }} alt="logo"/>;
                    } else {
                        return <div/>;
                    }
                }}
            </FormDataConsumer>
            <RadioButtonGroupInput label="Status" source="status" choices={[
                {
                    name: 'ACTIVE'
                },
                {
                    name: 'INACTIVE'
                },
                {
                    name: 'PENDING'
                },
                {
                    name: 'REJECTED'
                },
            ]} optionText="name" optionValue="name"/>

            <CheckboxGroupInput source="types" format={v => {
                let actualVals = [];
                if (Array.isArray(v)) {
                    actualVals = v;
                } else {
                    const parsed = v.split('');
                    parsed.forEach((r, i) => {
                        if (r === '1') {
                            actualVals.push(i + 1);
                        }
                    });
                }
                return actualVals;
            }} parse={v => {
                let defRole = '00000';
                v.map(h => {
                    defRole = defRole.replaceAt(h - 1, '1');
                });
                return defRole;
            }} choices={[
                {
                    id: 1,
                    name: 'Corporate'
                },
                {
                    id: 2,
                    name: 'Education'
                },
                {
                    id: 3,
                    name: 'Vendor'
                },
                {
                    id: 4,
                    name: 'Certification'
                },
                {
                    id: 5,
                    name: 'Industry'
                },
            ]} optionText="name"
            />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    console.log(formData);
                    return <ReferenceInput
                        perPage={200}
                        source="address"
                        reference="company_addresses"
                        filter={{ "company": { _eq: formData.id } }}
                    >
                        <SelectInput optionText="office_name" />
                    </ReferenceInput>
                }}
            </FormDataConsumer>
            <TextInput label="Headline" source="profile_field.headline" />
            <TextInput label="Website" source="profile_field.website" type="url" />
            <TextInput source="phone" />
            <TextInput source="email" type="email" />
            <TextInput label="Country Code" source="country_code" />
            <TextInput label="Speciality" source="profile_field.specialities"/>
            <ReferenceInput
                perPage={200}
                label="Industry Type"
                source="industry_type"
                reference="global_company_industry_types">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <DateInput source="profile_field.founded" label="founded"/>
        </SimpleForm>
    </Edit>
);
