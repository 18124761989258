import React from 'react';
import {
    SimpleForm,
    TextInput,
    ImageField,
    RadioButtonGroupInput,
    NumberInput,
    Create,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const VideoCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <ReferenceInput
                label="User"
                source="owner"
                reference="global_users"
                filterToQuery={searchText => ({ email: { _ilike: `%${searchText}%` } })}>
                <AutocompleteInput
                    optionText="email"
                    optionValue="id"
                    focusInputOnSuggestionClick={true}
                    //onChange={value => console.log("val ", value)}
                />
            </ReferenceInput>

            <TextInput source="title" />
            <RadioButtonGroupInput source="status" choices={[
                {
                    name: 'draft'
                },
                {
                    name: 'pending'
                },
                {
                    name: 'active'
                },
            ]} optionText="name" optionValue="name" />
            <ImageField source="thumbnail" />
            <TextInput source="thumbnail" />
            <TextInput source="subtitle" />
            <NumberInput source="pricing.IDR" label="Price in IDR" defaultValue={0}/>
            <NumberInput source="duration_seconds" defaultValue={0}/>
            <TextInput source="video_url" label="JW Video ID"/>
            <RichTextInput source="summary" />
            <NumberInput source="count_content" defaultValue={0}/>
            <NumberInput source="count_reviews" defaultValue={0}/>
            <NumberInput source="average_rating" defaultValue={0}/>
            <NumberInput source="count_discussions" defaultValue={0}/>
        </SimpleForm>
    </Create>
);
