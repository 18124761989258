import React from 'react';
import { DateInput, Edit, SimpleForm, TextInput, NumberInput, ReferenceInput, SelectInput, RadioButtonGroupInput, FormDataConsumer} from 'react-admin';
import { Typography } from '@material-ui/core';

export const BookEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="isbn" />
            <TextInput source="title" />
            <NumberInput source="pricing.IDR" />
            <RadioButtonGroupInput source="status" choices={[
                {
                    name: 'draft'
                },
                {
                    name: 'pending'
                },
                {
                    name: 'active'
                },
            ]} optionText="name" optionValue="name" />
            <ReferenceInput source="publisher_id" reference="academy_book_publishers"><SelectInput optionText="name" /></ReferenceInput>
            <TextInput source="slug" />
            <TextInput source="thumbnail" />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.thumbnail) {
                        return <img src={formData.thumbnail} style={{
                            margin: 10,
                            width: 260,
                            height: 260,
                        }} alt="Thumbnail"/>;
                    } else {
                        return <div/>;
                    }
                }}
            </FormDataConsumer>
            <NumberInput source="edition" />
            <TextInput source="author_written" />
            <NumberInput source="copyright_year" />
            <ReferenceInput reference="academy_course_categories" source="category"><SelectInput optionText="name" /></ReferenceInput>
            <ReferenceInput reference="academy_book_types" source="book_type"><SelectInput optionText="name" /></ReferenceInput>
            <ReferenceInput perPage={200} reference="global_users" source="owner"><SelectInput optionText="email" /></ReferenceInput>
            <TextInput source="summary" />
            <TextInput source="publisher_written" />
            <TextInput source="teaser_file" />
            <TextInput source="book_fields.url" />
            <NumberInput source="duration_seconds" />
            <DateInput source="last_modified" disabled/>
            <NumberInput source="count_content" disabled/>
            <NumberInput source="count_reviews" disabled/>
            <DateInput source="date_added" disabled/>
            <NumberInput source="average_rating" disabled/>

            <NumberInput source="count_discussions" disabled/>


            <Typography color='error' variant='h4'>DELETE ITEM WITH CHANGE THIS FIELD TO CURRENT
                DATE</Typography>
            <DateInput source="deletedAt" />

        </SimpleForm>
    </Edit>
);
