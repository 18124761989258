import { Filter, ReferenceInput, SelectInput, TextInput, List, RadioButtonGroupInput } from 'react-admin';
import React from 'react';

export const CompanyFilter = (props) => (
    <Filter {...props}>

        <TextInput label="Legal Name" source="legal_name._ilike" alwaysOn parse={value => {
            if(value === "" || value === "%%" || value === "%"){
                return "";
            }else {
                const val = value.split("%")
                    .join("");
                return `%${val}%`
            }

        }} />

        <TextInput label="Brand Name" source="brand_name._ilike" alwaysOn parse={value => {
            if(value === "" || value === "%%" || value === "%"){
                return "";
            }else {
                const val = value.split("%")
                    .join("");
                return `%${val}%`
            }

        }} />

        <SelectInput label="Status" source="status._eq" choices={[
                {
                    name: 'ACTIVE'
                },
                {
                    name: 'INACTIVE'
                },
                {
                    name: 'PENDING'
                },
                {
                    name: 'REJECTED'
                },
            ]} optionText="name" optionValue="name" />
        <SelectInput source="types._ilike" label="Type" choices={[
            {
                name: 'Corporate',
                value: '1____'
            },
            {
                name: 'Education',
                value: '_1___'
            },
            {
                name: 'Vendor',
                value: '__1__'
            },
            {
                name: 'Certification',
                value: '___1_'
            },
            {
                name: 'Industry',
                value: '____1'
            }
        ]} optionText="name" optionValue="value" />
    </Filter>
);
