import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    EditButton,
    TextInput,
    ReferenceField,
    SelectInput,
    DateField,
    NumberField,
    ImageField,
    ReferenceInput,
    AutocompleteInput,
    Filter,
    RadioButtonGroupInput
} from 'react-admin';

export const Events = props => (
    <List filters={<EventFilter />}{...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="status" />
            <TextField source="title" />
            <ReferenceField source="type" reference="event_types" allowEmpty><TextField source="name" /></ReferenceField>
            <TextField source="slug" />
            <ReferenceField source="category" reference="academy_course_categories" allowEmpty><TextField source="name" /></ReferenceField>
            <TextField source="pricing.IDR" label="Pricing in IDR"/>
            <ReferenceField source="creator" reference="global_users" allowEmpty><TextField source="name" /></ReferenceField>
            <ImageField source="thumbnail" />
            <DateField source="dateend" />
            <DateField source="datestart" />
            {/*<TextField source="summary" />*/}
            <TextField source="location_info.lat" />




            {/*<DateField source="last_modified" />
            <NumberField source="count_reviews" />
            <DateField source="date_added" />
            <NumberField source="average_rating" />
            <TextField source="deletedAt" />
            <TextField source="schedule_fields" />*/}
            {/*<TextField source="description" />*/}
        </Datagrid>
    </List>
);


const EventFilter = (props) => (
    <Filter {...props}>

        <RadioButtonGroupInput label="Status" source="status._eq" choices={[
            {
                name: 'draft'
            },
            {
                name: 'pending'
            },
            {
                name: 'active'
            },
        ]} optionText="name" optionValue="name" />
        <TextInput label="Title" source="title._ilike" alwaysOn/>
        <ReferenceInput
            source="category"
            reference="academy_course_categories"
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput
            source="type"
            reference="event_types"
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>
    </Filter>
);
