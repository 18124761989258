import React from 'react';
import {
    Edit,
    ImageField,
    RadioButtonGroupInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';

export const CourseEdit = props => (
    <Edit {...props}>
        <SimpleForm>

                    <TextInput disabled source="id" />
                    <TextInput disabled source="requirements" />
                    <ImageField source="thumbnail" />
                    <TextInput source="thumbnail" />
                    {/*<DisabledInput source="creator" />*/}
                    <ReferenceField source="creator" reference="global_users"><TextField source="name" /></ReferenceField>
                    <RadioButtonGroupInput source="status" choices={[
                        {
                            name: 'draft'
                        },
                        {
                            name: 'pending'
                        },
                        {
                            name: 'active'
                        },
                    ]} optionText="name" optionValue="name" />
                    <TextInput disabled source="active_marketing" />
                    <TextInput source="slug" />
                    <ReferenceInput source="price_tier" reference="academy_price_tier">
                        <SelectInput optionText="pricing.IDR" />

                    </ReferenceInput>
                    <ReferenceInput source="category" reference="academy_course_categories">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput disabled source="goals" />
                    <TextInput disabled source="topic" />
                    <TextInput disabled source="video" />
                    <TextInput disabled source="subtitle" />
                    <TextInput disabled source="target_audience" />
                    <TextInput disabled source="welcome" />
                    <TextInput disabled source="language" />
                    <TextInput source="title" />
                    <TextInput disabled source="type" />
                    <TextInput source="congratulation" />
                    <RichTextInput source="description" validation={{ required: true }} />

                    <TextInput disabled source="date_added" />
                    <TextInput disabled source="deletedAt" />
                    <TextInput disabled source="last_modified" />

            <TextInput disabled source="level" />
                    <TextInput disabled source="count_section" />
                    <TextInput disabled source="count_enrollment" />
                    <TextInput disabled source="count_lesson" />
                    <TextInput disabled source="duration_seconds" />
                    <TextInput disabled source="average_rating" />
                    <TextInput disabled source="estimated_duration" />
        </SimpleForm>
    </Edit>
);
