import { Filter, TextInput, } from 'react-admin';
import React from 'react';

export const NameFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search Name" source="name._ilike" alwaysOn parse={value => {
            if(value === "" || value === "%%" || value === "%"){
                return "";
            }else {
                const val = value.split("%")
                    .join("");
                return `%${val}%`
            }

        }} />
    </Filter>
);


export const ModuleNameFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search Name" source="module_name._ilike" alwaysOn parse={value => {
            if(value === "" || value === "%%" || value === "%"){
                return "";
            }else {
                const val = value.split("%")
                    .join("");
                return `%${val}%`
            }

        }} />
    </Filter>
);
