import React from 'react';

import {
    List,
    Datagrid,
    TextField,
    EmailField,
    EditButton,
    ReferenceField,
    DateField,
    NumberField,
    UrlField,
    ImageField,
} from 'react-admin';

import CustomAvatar from '../CustomAvatar'
import { CompanyFilter } from './company_filter'

export const Companies = props => (
    <List filters={<CompanyFilter />} {...props}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <DateField source="date_added" />
            <CustomAvatar source="logo" />
            <TextField source="status" />
            <TextField source="legal_name" />
            <TextField source="brand_name" />
            <TextField source="types" />
            <NumberField source="latest_balance" />
            <DateField label="Founded" source="profile_field.founded" />
            <TextField label="Headline" source="profile_field.headline" />
            <TextField label="Country Code" source="country_code" />
            <TextField label="Speciality" source="profile_field.specialities" />
            <UrlField label="Website" source="profile_field.website" />
            <ReferenceField source="address" reference="company_addresses" allowEmpty>
                <TextField source="office_name" />
            </ReferenceField>
            <ReferenceField label="Industry Type" source="industry_type" reference="global_company_industry_types" allowEmpty>
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);