import React from 'react';
import { DateInput, Edit, SimpleForm, TextInput} from 'react-admin';
import { Typography } from '@material-ui/core';

export const BookPublisherEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="name" />
            <TextInput source="address" />
            <TextInput source="publisher_fields.url" />
            <Typography color='error' variant='h4'>DELETE ITEM WITH CHANGE THIS FIELD TO CURRENT
                DATE</Typography>
            <DateInput source="deletedAt" />
        </SimpleForm>
    </Edit>
);
