import React from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    CheckboxGroupInput,
    DateInput,
    Edit,
    FormDataConsumer,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    TextField,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    BooleanInput
} from 'react-admin';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import EllipField from './EllipField';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles(theme => ({
    media: {
        width: '320px',
        padding: '12px'
    },
    common: {
        display: 'block',
    }
}));

export const CommonEdit = props => {

    const classes = useStyles();

    return (
        <Edit {...props}>
            <SimpleForm>
                <FormDataConsumer>
                    {formDataProps => {
                        return (
                            <div>
                                {
                                    props.options.schema.edit.sources.map(source => {
                                        switch (source.type) {
                                            case 'Text':
                                                return <TextInput source={source.name}
                                                                  label={source.title}
                                                                  className={classes.common}
                                                                  disabled={source.disabled}
                                                                  key={source.name}/>;
                                            case 'Date':
                                                return <DateInput source={source.name}
                                                                  className={classes.common}
                                                                  label={source.title}
                                                                  disabled={source.disabled}
                                                                  key={source.name}/>;
                                            case 'Boolean':
                                                return <BooleanInput source={source.name}
                                                                  className={classes.common}
                                                                  label={source.title}
                                                                  disabled={source.disabled}
                                                                  key={source.name}/>;
                                            case 'Number':
                                                return <NumberInput source={source.name}
                                                                    className={classes.common}
                                                                    label={source.title}
                                                                    disabled={source.disabled}
                                                                    key={source.name}/>;
                                            case 'NumberDecimals':
                                                return <NumberInput source={source.name}
                                                                    options={{ maximumFractionDigits: 6 }}
                                                                    className={classes.common}
                                                                    label={source.title}
                                                                    disabled={source.disabled}
                                                                    key={source.name}/>;
                                            case 'Delete':
                                                return (
                                                    <div>
                                                        <Typography color='error'
                                                                    variant='subtitle'>DELETE ITEM
                                                            WITH
                                                            CHANGE THIS FIELD TO CURRENT
                                                            DATE</Typography>
                                                        <DateInput label={source.title}
                                                                   className={classes.common}
                                                                   source={source.name}
                                                                   key={source.name}/>
                                                    </div>
                                                );
                                            case 'Roles':
                                                return <CheckboxGroupInput
                                                    label={source.title}
                                                    source={source.name}
                                                    className={classes.common}
                                                    key={source.name}
                                                    format={v => {
                                                        if (v === null || v === undefined) {
                                                            v = '00000010000';
                                                        }
                                                        let actualVals = [];
                                                        if (Array.isArray(v)) {
                                                            actualVals = v;
                                                        } else {
                                                            const parsed = v.split('');
                                                            parsed.forEach((r, i) => {
                                                                if (r === '1') {
                                                                    actualVals.push(i + 1);
                                                                }
                                                            });
                                                        }
                                                        return actualVals;
                                                    }}
                                                    parse={v => {
                                                        let defRole = '00000010000';
                                                        v.map(h => {
                                                            defRole = defRole.replaceAt(h - 1, '1');
                                                        });
                                                        return defRole;
                                                    }} choices={[
                                                    {
                                                        id: 1,
                                                        name: 'Organization Administrator'
                                                    },
                                                    {
                                                        id: 2,
                                                        name: 'Organization Department Head'
                                                    },
                                                    {
                                                        id: 3,
                                                        name: 'Organization Finance Admin'
                                                    },
                                                    {
                                                        id: 4,
                                                        name: 'Organization HR Admin'
                                                    },
                                                    {
                                                        id: 5,
                                                        name: 'Organization HR Assistant'
                                                    },
                                                    {
                                                        id: 6,
                                                        name: 'Organization Payroll Master'
                                                    },
                                                    {
                                                        id: 7,
                                                        name: 'Organization Staff'
                                                    },
                                                    {
                                                        id: 8,
                                                        name: 'Organization Supervisor'
                                                    }, {
                                                        id: 9,
                                                        name: 'Holding Administrator'
                                                    },{
                                                        id: 10,
                                                        name: 'Organization Branch Administrator'
                                                    },{
                                                        id: 11,
                                                        name: 'Organization WB Administrator'
                                                    }
                                                ]} optionText="name"
                                                />;
                                            case 'Choices':
                                                return <RadioButtonGroupInput
                                                    label={source.title}
                                                    source={source.name}
                                                    className={classes.common}
                                                    key={source.name}
                                                    choices={source.choices}
                                                    optionText="name"
                                                    optionValue="value"
                                                />;
                                            case 'Texts':
                                                return <ArrayInput label={source.title}
                                                                   source={source.name}
                                                                   className={classes.common}
                                                                   key={source.name}>
                                                    <SimpleFormIterator>
                                                        <TextInput defaultValue={''}/>
                                                    </SimpleFormIterator>
                                                </ArrayInput>;
                                            case 'Reference':
                                                if (source.reference.type === 'Text') {
                                                    if (source.filter) {


                                                        return <ReferenceInput
                                                            perPage={200}
                                                            label={source.title}
                                                            className={classes.common}
                                                            disabled={source.disabled}
                                                            source={source.reference.source}
                                                            reference={source.reference.reference}
                                                            filter={{ [source.filter.where]: { _eq: formDataProps.formData[source.filter.refer] } }}
                                                        >
                                                            <SelectInput
                                                                disabled={source.disabled}
                                                                optionText={source.reference.target}/>
                                                        </ReferenceInput>;
                                                    } else {
                                                        return <ReferenceInput
                                                            perPage={200}
                                                            label={source.title}
                                                            source={source.reference.source}
                                                            className={classes.common}
                                                            reference={source.reference.reference}>
                                                            <SelectInput
                                                                optionText={source.reference.target}/>
                                                        </ReferenceInput>;
                                                    }
                                                }
                                            case 'ReferenceInfo':
                                                if (source.reference.type === 'Text') {
                                                    if (source.filter) {


                                                        return <ReferenceInput
                                                            perPage={200}
                                                            label={source.title}
                                                            className={classes.common}
                                                            disabled={source.disabled}
                                                            source={source.reference.source}
                                                            reference={source.reference.reference}
                                                            filter={{ [source.filter.where]: { _eq: formDataProps.formData[source.filter.refer] } }}
                                                        >
                                                            <SelectInput
                                                                disabled={source.disabled}
                                                                optionText={source.reference.target}/>
                                                        </ReferenceInput>;
                                                    } else {
                                                        return <ReferenceInput
                                                            perPage={200}
                                                            label={source.title}
                                                            source={source.reference.source}
                                                            className={classes.common}
                                                            reference={source.reference.reference}>
                                                            <SelectInput
                                                                optionText={source.reference.target}/>
                                                        </ReferenceInput>;
                                                    }
                                                }
                                            case 'Image':
                                                return (<div>
                                                    <TextInput
                                                        source={source.name}
                                                        className={classes.common}
                                                        label={source.title}
                                                        disabled={source.disabled}
                                                        key={source.name}/>
                                                    <FormDataConsumer>
                                                        {({ formData, ...rest }) => {
                                                            if (formData[source.name]) {
                                                                return (
                                                                    <img
                                                                        src={formData[source.name]}
                                                                        className={classes.media}
                                                                        alt={source.title}/>
                                                                );
                                                            }

                                                        }}
                                                    </FormDataConsumer>
                                                </div>);
                                            case 'AutoComplete':
                                                if (source.filter && formDataProps.formData[source.filter.refer]) {
                                                    return <ReferenceInput
                                                        perPage={200}
                                                        label={source.title}
                                                        className={classes.common}
                                                        source={source.reference.source}
                                                        reference={source.reference.reference}
                                                        filter={{ [source.filter.where]: { _eq: formDataProps.formData[source.filter.refer] } }}
                                                        filterToQuery={searchText => ({
                                                            [source.reference.target]: { _ilike: `%${searchText}%` }
                                                        })}
                                                    >
                                                        <AutocompleteInput
                                                            label={source.title}
                                                            optionText={source.reference.target}
                                                            className={classes.common}
                                                            optionValue="id"
                                                            focusInputOnSuggestionClick={true}/>
                                                    </ReferenceInput>;
                                                } else {
                                                    return <ReferenceInput
                                                        perPage={200}
                                                        label={source.title}
                                                        source={source.reference.source}
                                                        className={classes.common}
                                                        filterToQuery={searchText => ({
                                                            [source.reference.target]: { _ilike: `%${searchText}%` }
                                                        })}
                                                        reference={source.reference.reference}>
                                                        <AutocompleteInput
                                                            label={source.title}
                                                            optionText={source.reference.target}
                                                            className={classes.common}
                                                            optionValue="id"
                                                            focusInputOnSuggestionClick={true}/>
                                                    </ReferenceInput>;
                                                }
                                            case 'Master':
                                                return <div>
                                                    <TextInput source={source.name}
                                                               key={source.name}
                                                               disabled/>
                                                    <Alert severity="info">ignore error on these
                                                        fields (with Read Only identifiers), it's
                                                        not indicate anything,
                                                        because it's just Read Only!</Alert>
                                                    <br/>
                                                    <ReferenceInput
                                                        perPage={200}
                                                        source={`__read_only_${source.reference.source}`}
                                                        label={source.name}
                                                        className={classes.common}
                                                        reference={source.reference.reference}>
                                                        <SelectInput
                                                            optionText={source.reference.name}
                                                            optionValue={source.reference.target}
                                                        />
                                                    </ReferenceInput>
                                                    <FormDataConsumer>
                                                        {({ formData, ...rest }) => {
                                                            formData[source.name] = formData[`__read_only_${source.reference.source}`];
                                                        }}
                                                    </FormDataConsumer>
                                                </div>;
                                            case 'Gender':
                                                return <RadioButtonGroupInput
                                                    label={source.title}
                                                    source={source.name}
                                                    choices={[
                                                        {
                                                            name: 'M',
                                                            label: 'Male'
                                                        },
                                                        {
                                                            name: 'F',
                                                            label: 'Female'
                                                        },
                                                    ]} optionText="label"
                                                    optionValue="name"/>;
                                        }
                                    })
                                }
                            </div>
                        );
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
}
