import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    EditButton,
    TextInput,
    ReferenceField,
    SelectInput,
    DateField,
    NumberField,
    ImageField,
    ReferenceInput,
    AutocompleteInput,
    Filter,
    RadioButtonGroupInput
} from 'react-admin';

export const EventTypes = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="code" />
            <ImageField source="thumbnail" />
            <ImageField source="fa_class" label="Icon"/>
            <EditButton/>

            {/*<DateField source="last_modified" />
            <DateField source="date_added" />
            <TextField source="deletedAt" />*/}

        </Datagrid>
    </List>
);
