import { Filter, TextInput, RadioButtonGroupInput } from 'react-admin';
import React from 'react';

export const VideoFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Title" source="title._ilike" alwaysOn parse={value => {
            if(value === "" || value === "%%" || value === "%"){
                return "";
            }else {
                const val = value.split("%")
                    .join("");
                return `%${val}%`
            }

        }} />
        <RadioButtonGroupInput label="Status" source="status._eq" choices={[
            {
                name: 'draft'
            },
            {
                name: 'pending'
            },
            {
                name: 'active'
            },
        ]} optionText="name" optionValue="name" />
    </Filter>
);
