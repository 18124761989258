import React from 'react';
import { List, Datagrid, TextField, EditButton} from 'react-admin';
import DeletedStatus from '../common/deleted_status';
import CustomAvatar from '../CustomAvatar'

import { NameFilter } from '../common/name_filter';
export const Banks = props => (
    <List filters={<NameFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <CustomAvatar source="logo" />
            <TextField source="code" label="Bank Code"/>
            <TextField source="swift" label="Swift Code"/>
            <DeletedStatus source="deletedAt" label="Status"/>
            <EditButton />
        </Datagrid>
    </List>
);
