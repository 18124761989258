import React from 'react';

import {
    ReferenceManyField,
    TextField,
    EmailField,
    NumberField,
    DateField,
    ReferenceField,
    Datagrid
} from 'react-admin';

export const CompanyEmployee = props => (
        <Datagrid rowClick="show" {...props}>
            <TextField source="id" />
            <ReferenceField source="user" reference="global_users"><TextField source="name" /></ReferenceField>
            <EmailField source="email" />
            <TextField source="status" />
            <NumberField source="current_salary" />
            <DateField source="start_date" />
            {/*TODO: <DateField source="role" />*/}
            <ReferenceField source="organization" reference="company_organizations" allowEmpty={true}><TextField source="name" /></ReferenceField>
            <ReferenceField source="supervisor_id" reference="global_users" allowEmpty={true}><TextField source="email" /></ReferenceField>
            <ReferenceField source="rank" reference="company_employee_ranks" allowEmpty={true}><TextField source="name" /></ReferenceField>
            <ReferenceField source="position" reference="company_employee_positions" allowEmpty={true}><TextField source="name" /></ReferenceField>
            <TextField source="type" />
            <ReferenceField source="office_address" reference="company_addresses" allowEmpty={true}><TextField source="office_name" /></ReferenceField>
            <TextField source="regno" />
        </Datagrid>
);
