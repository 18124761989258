import React from 'react';
import { Create, ReferenceInput, SelectInput, SimpleForm, TextInput, FormDataConsumer } from 'react-admin';
import { Avatar } from '@material-ui/core';

export const CourseCreateCategories = props => (
    <Create  {...props}>
        <SimpleForm>
            <ReferenceInput
                perPage={200}
                source="parent"
                reference="academy_course_categories">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="code" />
            <TextInput source="name" />
            <TextInput source="thumbnail" />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if(formData.thumbnail) return <img src={formData.thumbnail} style={{
                        margin: 10,
                        width: 260,
                        height: 260,
                    }} alt="Thumbnail"/>
                    else return <div />
                }}
            </FormDataConsumer>
            <TextInput source="fa_class" label="icon"/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if(formData.fa_class) return <img src={formData.fa_class} style={{
                        margin: 10,
                        width: 60,
                        height: 60,
                    }} alt="Icon"/>
                    else return <div />
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create >
);
