import React from 'react';
import Chip from '@material-ui/core/Chip';


const DeletedStatus = ({ record = {}, source }) => {
    if (record[source] != null) {
        return <Chip size="small" label="DELETED" color="secondary"/>
    }else {
        return <Chip size="small" label="ACTIVE" color="primary"/>
    }
};

export default DeletedStatus;