import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField, DateField} from 'react-admin';
import { NameFilter } from '../common/name_filter';

export const Cities = props => (
    <List filters={<NameFilter />}{...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <ReferenceField source="province" reference="global_provinces" allowEmpty><TextField source="name" /></ReferenceField>
            <TextField source="code" />
            <EditButton/>
        </Datagrid>
    </List>
);
