import React from 'react';
import {
    DateInput,
    Create,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    SimpleForm,
    TextInput
} from 'react-admin';


export const ProvinveCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="code" />
            <TextInput source="name" />
            <NumberInput source="minimum_wage" />
        </SimpleForm>
    </Create>
);
