import React from 'react';
import {
    Edit,
    SimpleForm,
    ReferenceInput,
    TextField,
    SelectInput,
    TextInput,
    ReferenceField,
    ImageField,
    DateInput,
    RadioButtonGroupInput,
    NumberInput,

} from 'react-admin';

import { Typography } from '@material-ui/core';

export const CourseCategoryEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" />
            <ReferenceInput source="parent" reference="academy_course_categories" allowEmpty={true}>
                <SelectInput optionText="name"  />
            </ReferenceInput>
            <ImageField source="thumbnail" />
            <TextInput source="thumbnail" />
            <TextInput source="name" />
            <TextInput source="code" />

            <ImageField source="fa_class" />
            <TextInput source="fa_class" />
            <TextInput disabled source="count_course" />
            <TextInput disabled source="last_modified" />
            <TextInput disabled source="date_added" />
            <Typography color='error' variant='h4'>DELETE ITEM WITH CHANGE THIS FIELD TO CURRENT DATE</Typography>
            <DateInput source="deletedAt" />
        </SimpleForm>
    </Edit>
);
