import React from 'react';
import {
    DateInput,
    Edit,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    SimpleForm,
    TextInput
} from 'react-admin';

import { Typography } from '@material-ui/core';

export const SkillEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <ReferenceInput perPage={200} source="parent" reference="people_skills"><SelectInput optionText="name" /></ReferenceInput>
            <TextInput source="name" />
            <Typography color='error' variant='h4'>DELETE ITEM WITH CHANGE THIS FIELD TO CURRENT DATE</Typography>
            <TextInput source="deletedAt" />
        </SimpleForm>
    </Edit>
);
