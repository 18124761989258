import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField, DateField} from 'react-admin';
import { NameFilter } from '../common/name_filter';

export const Degrees = props => (
    <List filters={<NameFilter />}{...props}>
        <Datagrid rowClick="edit">
            <DateField source="date_added" />
            <TextField source="id" />
            <TextField source="name" />
            <EditButton/>
            {/*<TextField source="last_modified" />
            <TextField source="deleted_at" />*/}

        </Datagrid>
    </List>
);
