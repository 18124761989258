import React from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    EmailField, 
    EditButton, 
    ReferenceField, 
    DateField, 
    NumberField, 
    ImageField,
} from 'react-admin';
import { CourseFilter } from './course_filter';

export const Courses = props => (
    <List filters={<CourseFilter />}  {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
            {/*<TextField source="requirements" />*/}
            <ImageField source="thumbnail" />
            <ReferenceField source="creator" reference="global_users"><TextField source="name" /></ReferenceField>
            <TextField source="status" />
            <TextField source="slug" />
            <TextField source="topic" />
            <NumberField source="price_tier" />
            <ReferenceField source="price_tier" reference="academy_price_tier" allowEmpty={true}><TextField source="pricing.IDR" /></ReferenceField>
            <ReferenceField source="category" reference="academy_course_categories"><TextField source="name" /></ReferenceField>
            <DateField source="date_added" />
            <NumberField source="average_rating" />
            <TextField source="estimated_duration" />
            <DateField source="last_modified" />
            <TextField source="active_marketing" />
            {/*<TextField source="goals" />*/}
            {/*<ReferenceField source="organization_id" reference="company_organizations"><TextField source="name" /></ReferenceField>*/}
            <TextField source="video" />
            <NumberField source="count_lesson" />
            <NumberField source="duration_seconds" />
            {/*<TextField source="target_audience" />*/}
            <TextField source="welcome" />
            <TextField source="language" />
            <NumberField source="count_section" />
            <NumberField source="count_enrollment" />
            <TextField source="deletedAt" />
            <NumberField source="type" />
            <TextField source="congratulation" />
            <TextField source="level" />
            {/*<TextField source="description" />*/}
            <EditButton />
        </Datagrid>
    </List>
);
