import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    EditButton,
    ReferenceField,
    DateField,
    NumberField,
    ImageField,
    UrlField
} from 'react-admin';

export const BookPublisher = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="address" />
            <UrlField source="publisher_fields.url" label="Publisher URL"/>
            {/*<TextField source="deletedAt" />*/}
            <EditButton/>
        </Datagrid>
    </List>
);
