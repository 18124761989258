import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    EditButton,
    ReferenceField,
    DateField,
    NumberField,
    ImageField,
    UrlField
} from 'react-admin';
import { CourseFilter } from './course_filter';

export const Books = props => (
    <List filters={<CourseFilter />}  {...props} sort={{ field: 'isbn', order: 'DESC' }} filter={{ isbn: {$ne: null} }}>
        <Datagrid rowClick="edit">
            <TextField source="isbn" />
            <DateField source="date_added"/>
            <TextField source="status" />
            <ReferenceField source="book_type" reference="academy_book_types" ><TextField source="name" /></ReferenceField>
            <TextField source="title" />
            <TextField source="author_written" />
            <NumberField source="edition"/>
            <TextField source="copyright_year"/>
            <TextField source="pricing.IDR"/>
            <ReferenceField source="publisher_id" reference="academy_book_publishers" allowEmpty><TextField source="name" /></ReferenceField>
            <ReferenceField reference="global_users" source="owner" allowEmpty><TextField source="email" /></ReferenceField>
            {/*<TextField source="deletedAt" />*/}
            <EditButton/>
        </Datagrid>
    </List>
);
