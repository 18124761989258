import React from 'react';
import { Admin, Resource, ListGuesser } from 'react-admin';
import hasuraDataProvider from 'ra-data-hasura-wlb';
import { UserList } from './components/user/users';
import { UserEdit } from './components/user/user_edit';
import { UserShow } from './components/user/user_show';
import { UserCreate } from './components/user/user_create';
import { CourseEdit } from './components/course/course_edit';
import { Courses } from './components/course/courses';
import { Placements } from './components/people/people_placement';
import { PlacementShow } from './components/people/people_placement_show';
import { PlacementEdit } from './components/people/people_placement_edit';
import { PlacementCreate } from './components/people/people_placement_create';
import { Transactions } from './components/user/transactions';
import { TransactionCreate } from './components/user/transaction_create';
import { Wallets } from './components/global/wallet_accounts';
import { CourseCategories } from './components/course/course_categories';
import { CourseCategoryEdit } from './components/course/course_categories_edit';
import { Companies } from './components/company/companies';
import { CompanyShow } from './components/company/company_show';
import { CompanyEdit } from './components/company/company_edit';
import { CompanyCreate } from './components/company/company_create';
import { CourseCreateCategories } from './components/course/course_category_create';
import { CoursePriceTier } from './components/course/course_price_tier';
import { CoursePriceTierEdit } from './components/course/course_price_tier_edit';
import { CoursePriceTierAdd } from './components/course/course_price_add';
import { BookTypes } from './components/course/book_types';
import { BookTypeEdit } from './components/course/book_type_edit';
import { BookTypeCreate } from './components/course/book_type_create';
import { BookPublisher } from './components/course/book_publishers';
import { BookPublisherEdit } from './components/course/book_publisher_edit';
import { BookPublisherCreate } from './components/course/book_publisher_create';
import { Books } from './components/course/books';
import { BookEdit } from './components/course/book_edit';
import { Events } from './components/course/events';
import { EventEdit } from './components/course/event_edit';
import { EventTypes } from './components/course/event_types';
import { EventTypeEdit } from './components/course/event_type_edit';
import { EventTypeCreate } from './components/course/event_type_create';
import { PeopleSkills } from './components/people/people_skills';
import { SkillEdit } from './components/people/people_skill_edit';
import { SkillCreate } from './components/people/people_skill_create';
import { Degrees } from './components/global/degrees';
import { DegreeEdit } from './components/global/degree_edit';
import { DegreeCreate } from './components/global/degree_add';
import { Faculties } from './components/global/faculties';
import { FacultyEdit } from './components/global/faculty_edit';
import { FacultyCreate } from './components/global/faculty_create';
import { Provinces } from './components/global/provinces';
import { ProvinveEdit } from './components/global/province_edit';
import { ProvinveCreate } from './components/global/province_create';
import { Cities } from './components/global/cities';
import { CityEdit } from './components/global/city_edit';
import { CityCreate } from './components/global/city_create';
import { Videos } from './components/course/videos';
import { VideoEdit } from './components/course/video_edit';
import { VideoCreate } from './components/course/video_create';
import { IndustryTypes } from './components/global/industry_types';
import { IndustryTypeEdit } from './components/global/industry_type_edit';
import { IndustryTypeCreate } from './components/global/industry_type_create';
import { Banks } from './components/global/banks';
import { BankCreate } from './components/global/bank_create';
import { BankEdit } from './components/global/bank_edit';
import { HolidayTypes } from './components/time/holiday_types';
import { HolidayTypeCreate } from './components/time/holiday_type_create';
import { HolidayTypeEdit } from './components/time/holiday_type_edit';
import { CommonList } from './components/common/CommonList';
import { CommonEdit } from './components/common/CommonEdit';
import { CommonCreate } from './components/common/CommonCreate';
import { ModuleSetting } from './components/global/module_setting'
import { ModuleSettingEdit } from './components/global/module_setting_edit'

import authProvider from './authProvider';
import Dashboard from './Dashboard';
import * as schema from './schema.json';
import Theme from './theme';

const BASE_URL = process.env.REACT_APP_GRAPHQL_BASE_URL;

const config = {
    'primaryKey': {
        'people_emails': 'email',
    }
}

const headers =
    {
        'content-type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    };

//console.log(headers);

const dataProvider = hasuraDataProvider(BASE_URL, headers, config);

const App = () => (
    <Admin theme={Theme} dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider} title="My Custom Admin">
        <Resource options={{ label: 'Users' }} name="global_users" list={UserList} show={UserShow}
                  edit={UserEdit} create={UserCreate}/>
        <Resource options={{ label: 'Courses' }} name="academy_courses" list={Courses}
                  edit={CourseEdit}/>
        <Resource options={{ label: 'Course Categories' }} name="academy_course_categories"
                  list={CourseCategories} edit={CourseCategoryEdit}
                  create={CourseCreateCategories}/>
        <Resource options={{ label: 'Course Price' }} name="academy_price_tier"
                  list={CoursePriceTier} edit={CoursePriceTierEdit} create={CoursePriceTierAdd}/>
        <Resource options={{ label: 'Placements' }} name="people_work_placements" list={Placements}
                  show={PlacementShow} edit={PlacementEdit} create={PlacementCreate}/>
            <Resource options={{ label: 'Company' }} name="company_profiles" list={Companies}
                      show={CompanyShow} edit={CompanyEdit} create={CompanyCreate}/>
            {<Resource options={{ label: 'Leave Policy Children' }} name="time_leave_policy_children"
                  list={ListGuesser}/>}

            <Resource options={{ label: 'Transactions' }} name="global_transactions"
                      list={Transactions}
                      create={TransactionCreate}/>
            <Resource options={{ label: 'Wallet Accounts' }} name="global_wallet_accounts"
                      list={Wallets}/>
            <Resource options={{ label: 'Wallet Accounts' }} name="global_wallet_accounts"
                      list={Wallets}/>
            <Resource options={{ label: 'Book Types' }} name="academy_book_types" list={BookTypes}
                      edit={BookTypeEdit} create={BookTypeCreate}/>
            <Resource options={{ label: 'Book Publisher' }} name="academy_book_publishers"
                      list={BookPublisher} edit={BookPublisherEdit} create={BookPublisherCreate}/>
        <Resource options={{ label: 'Books' }} name="academy_books" list={Books} edit={BookEdit}/>
        <Resource options={{ label: 'Events' }} name="event_schedules" list={Events}
                  edit={EventEdit}/>
        <Resource options={{ label: 'Event Types' }} name="event_types" list={EventTypes}
                  edit={EventTypeEdit} create={EventTypeCreate}/>
        <Resource options={{ label: 'People Skill' }} name="people_skills" list={PeopleSkills}
                  edit={SkillEdit} create={SkillCreate}/>
        <Resource options={{ label: 'Degree' }} name="global_degrees" list={Degrees}
                  edit={DegreeEdit} create={DegreeCreate}/>
        <Resource options={{ label: 'Faculties' }} name="global_faculties" list={Faculties}
                  edit={FacultyEdit} create={FacultyCreate}/>
        <Resource options={{ label: 'Provinces' }} name="global_provinces" list={Provinces}
                  edit={ProvinveEdit} create={ProvinveCreate}/>
        <Resource options={{ label: 'Cities' }} name="global_cities" list={Cities} edit={CityEdit}
                  create={CityCreate}/>
        <Resource options={{ label: 'Global Module Setting' }} name="global_module_settings" list={ModuleSetting} edit={ModuleSettingEdit}
                  />
        <Resource options={{ label: 'Videos' }} name="academy_videos" list={Videos} edit={VideoEdit}
                  create={VideoCreate}/>
            <Resource options={{ label: 'Industry Types' }} name="global_company_industry_types"
                      list={IndustryTypes} edit={IndustryTypeEdit}
                      create={IndustryTypeCreate}/>
            <Resource options={{ label: 'Banks' }} name="global_banks"
                      list={Banks} edit={BankEdit}
                      create={BankCreate}/>
            <Resource options={{ label: 'Holiday Type' }} name="time_holiday_types"
                      list={HolidayTypes} edit={HolidayTypeEdit}
                      create={HolidayTypeCreate}/>
        {
            schema.resources.map(source => {
                return <Resource
                    options={{ label: source.title, schema: source}} name={source.name}
                    list={CommonList} edit={CommonEdit}
                    create={CommonCreate}
                />;
            })
        }


    </Admin>);

export default App;
