import React from 'react';
import {
    Edit,
    SimpleForm,
    ReferenceInput,
    TextField,
    SelectInput,
    TextInput,
    ReferenceField,
    ImageField,
    DateInput,
    RadioButtonGroupInput,
    NumberInput,
    Show,
    RichTextField,
    TabbedShowLayout,
    Tab,
    Create
} from 'react-admin';

import { Typography } from '@material-ui/core';

export const CoursePriceTierAdd = props => (
    <Create {...props} transform={((data) => ({ ...data, pricing_idr: data.pricing?.IDR, pricing_usd: data.pricing?.USD }))}>
        <SimpleForm>
            <TextInput source="name" />
            <NumberInput source="pricing.IDR" label="Price in IDR"/>
            <NumberInput source="pricing.USD" label="Price in USD"/>
            {/*<TextInput source="date_added" />
            <TextInput source="last_modified" />*/}
        </SimpleForm>
    </Create>
);
