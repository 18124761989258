import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField} from 'react-admin';
import { NameFilter } from '../common/name_filter';

export const PeopleSkills = props => (
    <List filters={<NameFilter />}{...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="parent" reference="people_skills" allowEmpty><TextField source="name" /></ReferenceField>
            <TextField source="name" />
            {/*<TextField source="deletedAt" />*/}
            <EditButton />
        </Datagrid>
    </List>
);
