import React from 'react';
import {
    Datagrid,
    DateField,
    EditButton,
    EmailField,
    NumberField,
    ReferenceField,
    TextField
} from 'react-admin';

export const PlacementsReference = props => (
    <Datagrid {...props} rowClick="show">
        <TextField source="id"/>
        <ReferenceField source="user" reference="global_users"><TextField
            source="name"/></ReferenceField>
        <EmailField source="email"/>
        <ReferenceField source="company" reference="company_profiles"><TextField
            source="legal_name"/></ReferenceField>
        <TextField source="end_date"/>
        <TextField source="status"/>
        <NumberField source="current_salary"/>
        <DateField source="start_date"/>
        <TextField source="role"/>
        <ReferenceField source="organization" reference="company_organizations"><TextField
            source="name"/></ReferenceField>
        <ReferenceField source="supervisor_id" reference="global_users" allowEmpty={true}><TextField source="email"/></ReferenceField>
        <ReferenceField source="rank" reference="company_employee_ranks"><TextField source="name"/></ReferenceField>
        <ReferenceField source="position" reference="company_employee_positions"><TextField
            source="name"/></ReferenceField>
        <TextField source="deletedAt"/>
        <TextField source="type"/>
        <ReferenceField source="office_address" reference="company_addresses"><TextField
            source="office_name"/></ReferenceField>
        <TextField source="regno"/>
        <EditButton/>
    </Datagrid>
);
