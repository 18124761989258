import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';
import DeletedStatus from '../common/deleted_status';

import { NameFilter } from '../common/name_filter';

export const HolidayTypes = props => {
    console.log(props);
    return (
        <List filters={<NameFilter/>} {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id"/>
                <TextField source="name"/>
                <DeletedStatus source="deleted_at" label="Status"/>
                <EditButton/>
            </Datagrid>
        </List>
    );
};
