import { Filter, ReferenceInput, SelectInput, TextInput, List } from 'react-admin';
import React from 'react';

export const UserFilter = (props) => (
    <Filter {...props}>

        <TextInput label="Search" source="name._ilike" alwaysOn parse={value => {
            if(value === "" || value === "%%" || value === "%"){
                return "";
            }else {
                const val = value.split("%")
                    .join("");
                return `%${val}%`
            }

        }} />

    </Filter>
);
