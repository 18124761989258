import React from 'react';
import {
    Edit,
    FormDataConsumer,
    RadioButtonGroupInput,
    SimpleForm,
    TextInput,
    useNotify
} from 'react-admin';
import Button from '@material-ui/core/Button';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const UserEdit = props => {

    const [uuid, setUuid] = React.useState(undefined);
    const [salt, setSalt] = React.useState(0);
    const [password, setPassword] = React.useState(undefined);
    const notify = useNotify();

    const resendUrl = BASE_URL + '/global/accounts/resendVerification';

    const resendActivation = (email) => {
        axios.post(resendUrl, {
            email: email,
        })
            .then(function (response) {
                notify(`Activation sent`, 'success');
            })
            .catch(function (error) {
                notify(`Failed sending activation`, 'warning');
            });
    }

    const generatePassword = () => {

        if (password) {
            axios.get(`https://password-hashing.vercel.app/password?words=${password}`)
                .then(res => {
                    const resp = res.data;
                    setUuid(resp.hash);
                    setPassword(resp.hash);
                    setSalt(resp.saltRounds);
                });
        }else notify(`Password format invalid`, 'warning');
    };


    return (
    <Edit {...props}>
        <SimpleForm>

            <RadioButtonGroupInput source="status" choices={[
                {
                    name: 'REGISTERED'
                },
                {
                    name: 'ACTIVE'
                },
                {
                    name: 'INACTIVE'
                },
                {
                    name: 'DELETED'
                },
            ]} optionText="name" optionValue="name"/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    console.log('FD ', formData);
                    if (formData.status === 'REGISTERED') {
                        return (
                            <div><Button variant="outlined" color="primary"
                                         onClick={() => {
                                             resendActivation(formData.email);
                                         }}>Resend Activation</Button>
                                <br/>
                                <TextInput source="email"/>
                            </div>
                        );
                    } else {
                        return <TextInput source="email"/>;
                    }
                }}
            </FormDataConsumer>
            <TextInput source="roles"/>
            <TextInput disabled source="latest_balance"/>
            <TextInput disabled source="wallet_account"/>
            <TextInput source="name"/>
            <TextInput disabled source="id"/>
            <TextInput source="avatar"/>
            <TextInput source="biography" multiline/>
            <TextInput source="password" label="Password"
                       validation={{ required: true }} value={password}/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    let pass = formData.password;
                    if (pass) {
                        setPassword(pass);
                    }

                    if (uuid) {
                        setPassword(uuid);
                        //return <TextInput source="password" label="Hash" value={uuid}
                        //                  disabled/>;
                        return (
                            formData.password = uuid
                        );
                    }
                }}
            </FormDataConsumer>
            <Button variant="outlined" color="primary" onClick={generatePassword}>Set
                Password</Button>
        </SimpleForm>
    </Edit>
)};
