import React from 'react';
import {
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    TextField,
    SelectInput,
    ImageInput,
    TextInput,
    ReferenceField,
    ImageField,
    DateInput,
    RadioButtonGroupInput,
    AutocompleteInput,
    NumberInput,
    FormDataConsumer,
    CheckboxGroupInput
} from 'react-admin';

String.prototype.replaceAt=function(index, replacement) {
    return this.substr(0, index) + replacement+ this.substr(index + replacement.length);
}


export const CompanyCreate = props => (
    <Create  {...props}>
        <SimpleForm>
            <TextInput source="legal_name" />
            <TextInput source="brand_name" />
            <TextInput source="phone" />
            <TextInput source="email" type="email" />
            <DateInput source="profile_field.founded" label="founded"/>
            <TextInput source="logo" label="Logo Image URL">
                <ImageField source="src" title="title" />
            </TextInput>
            <CheckboxGroupInput source="__read_only_select_type" choices={[
                { id: 1, name: 'Corporate' },
                { id: 2, name: 'Education' },
                { id: 3, name: 'Vendor' },
                { id: 4, name: 'Certification' },
                { id: 5, name: 'Industry' },
            ]}
                //onChange={event => console.log("ev ", event)}
                                optionText="name"
            />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    let defRole = "00000"
                    if(formData.__read_only_select_type){
                        formData.__read_only_select_type.map(h => {
                            defRole = defRole.replaceAt(h-1, "1")
                        })
                        return (
                            formData.types = defRole
                        );
                    }
                }}
            </FormDataConsumer>
            <RadioButtonGroupInput label="Status" source="status" choices={[
                {
                    name: 'ACTIVE'
                },
                {
                    name: 'INACTIVE'
                },
                {
                    name: 'PENDING'
                },
                {
                    name: 'REJECTED'
                },
            ]} optionText="name" optionValue="name" />
            <TextInput label="Headline" source="profile_field.headline" />
            <TextInput label="Website" source="profile_field.website" type="url" />
            <TextInput label="Country Code" source="country_code" />
            <TextInput label="Speciality" source="profile_field.specialities"/>
            <ReferenceInput
                perPage={200}
                label="Industry Type"
                source="industry_type"
                reference="global_company_industry_types">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create >
);
