import React from 'react';
import {
    TabbedShowLayout,
    Tab,
    TextField,
    ImageField,
    Show,
    DateField,
    SelectField,
    EditButton,
    ReferenceManyField,
    Datagrid,
    ReferenceField,
    EmailField,
    NumberField

} from 'react-admin'

import CardActions from '@material-ui/core/CardActions';
import { CompanyEmployee } from './company_employee';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const CompanyShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
    </CardActions>
);

export const CompanyShow = (props) => (
    <Show actions={<CompanyShowActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <ImageField source="logo" />
                <TextField label="Id" source="id" />
                <TextField source="legal_name" />
                <TextField source="brand_name" />
                <TextField source="status" />
                <TextField source="types" />

                <DateField label="Founded" source="profile_field.founded" />
                <SelectField source="types" choices={[
                    {
                        name: 'Corporate',
                        value: '100'
                    },
                    {
                        name: 'Vendor',
                        value: '010'
                    },
                    {
                        name: 'Education',
                        value: '001'
                    },
                    {
                        name: 'Corporate, Vendor',
                        value: '110'
                    }, {
                        name: 'Education, Vendor',
                        value: '011'
                    },
                    {
                        name: 'Corporate, Education',
                        value: '101'
                    },
                    {
                        name: 'Corporate, Vendor, Education',
                        value: '111'
                    }
                ]} optionText="name" optionValue="value" />
            </Tab>
            <Tab label="contact">
                <TextField source="email" />
                <TextField source="Phone" />
            </Tab>
            <Tab label="Date">
                <DateField source="date_added" />
                <DateField source="last_modified" />
                <DateField source="last_modified" />
            </Tab>
            <Tab label="Employees">
                <ReferenceManyField label="Employees" reference="people_work_placements" target="company" source="id" {...props}>
                    <CompanyEmployee />
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
