import React from 'react';
import { List, Datagrid, TextField, EditButton, FunctionField} from 'react-admin';
import DeletedStatus from '../common/deleted_status';

import { NameFilter } from '../common/name_filter';
export const IndustryTypes = props => (
    <List filters={<NameFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
            <DeletedStatus source="deleted_at" label="Status"/>
            <EditButton />
        </Datagrid>
    </List>
);
