import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    NumberField,
    ImageField,
} from 'react-admin';
import { VideoFilter } from './video_filter'

export const Videos = props => (
    <List filters={<VideoFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="title" />
            <ImageField source="thumbnail" />
            <ReferenceField source="owner" reference="global_users" label="Creator"><TextField source="email" /></ReferenceField>
            <NumberField source="average_rating" />
            <TextField source="subtitle" />
            <NumberField source="pricing.IDR" label="Pricing (IDR)"/>
            <TextField source="status" />
            <NumberField source="duration_seconds" />
            <DateField source="date_added" />

            {/*<TextField source="summary" />*/}
            <DateField source="last_modified" />
            {/*<NumberField source="count_content" />*/}
            {/*<TextField source="video_url" />*/}
            <NumberField source="count_reviews" />
            {/*<NumberField source="count_discussions" />*/}
            <TextField source="deletedAt" />
        </Datagrid>
    </List>
);