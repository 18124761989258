import React from 'react';
import {
    DateInput,
    Edit,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    SimpleForm,
    TextInput,
    Create
} from 'react-admin';


export const SkillCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput perPage={200} source="parent" reference="people_skills"><SelectInput optionText="name" /></ReferenceInput>

        </SimpleForm>
    </Create>
);
