import React from 'react';
import { FunctionField } from 'react-admin';

const EllipField = ({ ...props }) => {
    let textToRender = props.record[props.source]
    if (typeof textToRender === 'string') {
        if (textToRender.length > 75) {
            textToRender = textToRender.replace(/(.{75})..+/, "$1...")
        }
    }
    return (
        <FunctionField {...props}
                       render={_ => `${textToRender}`}
        />
    );
};


export default EllipField;
