import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField, DateField} from 'react-admin';

import { NameFilter } from '../common/name_filter';
export const Faculties = props => (
    <List filters={<NameFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <EditButton />
        </Datagrid>
    </List>
);
