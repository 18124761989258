import React from 'react';
import { Create, SimpleForm, TextInput} from 'react-admin';

export const BookPublisherCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="address" />
            <TextInput source="publisher_fields.url" label="Publisher Url"/>
        </SimpleForm>
    </Create>
);
