import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    EditButton,
    ReferenceField,
    DateField,
    NumberField,
    ImageField
} from 'react-admin';

import { WalletFilter } from './wallter_filter'


export const Wallets = props => (
    <List filters={<WalletFilter />}  {...props}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="foreign_owner_id" reference="global_users" allowEmpty><TextField source="email" /></ReferenceField>
            <NumberField source="latest_balance" />
            <DateField source="last_modified" />
            <TextField source="foreign_table" />
            <DateField source="date_added" />
            <TextField source="account_type" />
            <TextField source="account_fields" />
        </Datagrid>
    </List>
);