import React from 'react';
import { List, Datagrid, TextField, EmailField, EditButton, ReferenceField, DateField, NumberField, ImageField } from 'react-admin';
import { PlacementFilter } from './people_placement_filter'

export const Placements = props => (
    <List filters={<PlacementFilter />}{...props}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="regno" label="Employee ID"/>
            <ReferenceField source="user" reference="global_users"><TextField source="name" /></ReferenceField>
            <EmailField source="email" />
            <ReferenceField source="company" reference="company_profiles"><TextField source="legal_name" /></ReferenceField>
            <ReferenceField source="job_profile" label="Job Profile/Position" reference="company_job_profiles"><TextField source="title" /></ReferenceField>
            <TextField source="end_date" />
            <TextField source="status" />
            <NumberField source="current_salary" />
            <DateField source="start_date" />
            <TextField source="role" />
            <ReferenceField source="organization" reference="company_organizations" allowEmpty><TextField source="name"/></ReferenceField>
            <ReferenceField source="supervisor_id" reference="global_users" allowEmpty><TextField source="email" /></ReferenceField>
            <ReferenceField source="rank" label="Level" reference="company_employee_ranks" allowEmpty><TextField source="name" /></ReferenceField>
            <ReferenceField source="position" label="Designation" reference="company_employee_positions" allowEmpty><TextField source="name" /></ReferenceField>
            <TextField source="type" />
            <TextField source="type_time" />
            <TextField source="type_place" />
            <TextField source="reference" label="Decree" />
            <ReferenceField source="office_address" reference="company_addresses" allowEmpty><TextField source="office_name" /></ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);
