import React from 'react';
import {
    DateInput,
    Edit,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    SimpleForm,
    TextInput
} from 'react-admin';

import { Typography } from '@material-ui/core';
export const DegreeEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="name" />
            <Typography color='error' variant='h4'>DELETE ITEM WITH CHANGE THIS FIELD TO CURRENT DATE</Typography>
            <DateInput source="deleted_at" />

        </SimpleForm>
    </Edit>
);
