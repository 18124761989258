import React from 'react';
import {
    DateInput,
    Edit, FormDataConsumer,
    SimpleForm,
    TextInput
} from 'react-admin';

import { Typography } from '@material-ui/core';

export const BankEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" label="Bank Name"/>
            <TextInput source="code" label="Bank Code"/>
            <TextInput source="swift" label="Swift Code"/>
            <TextInput source="logo" label="Logo Image URL" />
            <FormDataConsumer>
                {({
                      formData,
                      ...rest
                  }) => {
                    if (formData.logo) {
                        return (
                            <img
                                src={formData.logo}
                                alt="Bank Logo"/>
                        );
                    }

                }}
            </FormDataConsumer>
            <Typography color='error' variant='subtitle'>DELETE ITEM WITH CHANGE THIS FIELD TO CURRENT DATE</Typography>
            <DateInput source="deletedAt" />
        </SimpleForm>
    </Edit>
);
