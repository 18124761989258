import React from 'react';
import { Create, FormDataConsumer, SimpleForm, TextInput, useNotify, RadioButtonGroupInput, NumberInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import axios from 'axios';


export const UserCreate = props => {

    const [uuid, setUuid] = React.useState(undefined);
    const [salt, setSalt] = React.useState(0);
    const [password, setPassword] = React.useState(undefined);
    const notify = useNotify();

    const generatePassword = () => {

        if (password) {
            axios.get(`https://password-hashing.vercel.app/password?words=${password}`)
                .then(res => {
                    const resp = res.data;
                    setUuid(resp.hash);
                    setSalt(resp.saltRounds);
                });
        }else notify(`Password format invalid`, 'warning');
    };

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" label="Full Name" validation={{ required: true }}/>
                <TextInput source="email" type="email" validation={{ required: true }}/>
                <TextInput source="__read_only_password" type="password" label="Password"
                           validation={{ required: true }}/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        let pass = formData.__read_only_password;
                        if (pass) {
                            setPassword(pass);
                        }

                        if (uuid) {
                            return <TextInput source="password" label="Hash" defaultValue={uuid}
                                              disabled/>;
                        }
                    }}
                </FormDataConsumer>
                <br/>
                {
                    <Button variant="outlined" color="primary" onClick={generatePassword}>Set
                        Password</Button>
                }
                <TextInput source="avatar" type="url"/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData.avatar) {
                            return <img src={formData.avatar} style={{
                                margin: 10,
                                width: 260,
                                height: 260,
                            }} alt="Thumbnail"/>
                        } else {
                            return <div/>
                        }
                    }}
                </FormDataConsumer>
                <RadioButtonGroupInput source="status" choices={[
                    {
                        name: 'REGISTERED'
                    },
                    {
                        name: 'ACTIVE'
                    },
                    {
                        name: 'INACTIVE'
                    },
                    {
                        name: 'DELETED'
                    },
                ]} optionText="name" optionValue="name" />
                <NumberInput disabled source="latest_balance" defaultValue={0}/>
                <NumberInput disabled source="salt_rounds" label="Hash round" value={salt}/>
                <TextInput source="biography" multiline validation={{ required: true }} defaultValue={"-"}/>
            </SimpleForm>
        </Create>
    )
};
