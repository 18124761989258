import React from 'react';
import { List, Datagrid, TextField, EmailField, EditButton, ReferenceField, NumberField, DateField } from 'react-admin';
import { TransactionFilter } from './transaction_filter';

export const Transactions = props => (
    <List filters={<TransactionFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />

            <ReferenceField source="from" reference="global_users" allowEmpty={true}>
                <TextField source="email" />
            </ReferenceField>
            <ReferenceField source="to" reference="global_users" allowEmpty={true}>
                <TextField source="email" />
            </ReferenceField>

            <NumberField source="value" />
            <TextField source="transaction_item_table" />
            <TextField source="name" />
            <TextField source="transaction_modifiers" />

            <TextField source="deletedAt" />
            <DateField source="date_created" />
        </Datagrid>
    </List>
);