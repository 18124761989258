import React from 'react';
import Avatar from '@material-ui/core/Avatar';


const CustomAvatar = ({ record = {}, source }) =>

    <Avatar alt="Remy Sharp" src={record[source]} style={{
        margin: 10,
        width: 60,
        height: 60,
      }} />

export default CustomAvatar;