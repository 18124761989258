import React from 'react';
import {
    DateInput,
    Edit,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    SimpleForm,
    TextInput
} from 'react-admin';

import { Typography } from '@material-ui/core';

export const CityEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="name" />
            <ReferenceInput perPage={200} source="province" reference="global_provinces" sort={{ field: 'name', order: 'ASC' }} allowEmpty={true}>
                <SelectInput optionText="name"  /></ReferenceInput>

            <TextInput source="code" />

            <Typography color='error' variant='h4'>DELETE ITEM WITH CHANGE THIS FIELD TO CURRENT DATE</Typography>
            <TextInput source="deletedAt" />
        </SimpleForm>
    </Edit>
);
