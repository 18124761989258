import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
} from 'react-admin';
import { CourseCategoryFilter } from './course_category_filter'

export const CoursePriceTier = props => (
    <List filters={<CourseCategoryFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="pricing.IDR" label="Price in IDR"/>
            <TextField source="pricing.USD" label="Price in USD"/>
            {/*<TextField source="last_modified" />
            <TextField source="date_added" />
            <TextField source="deletedAt" />*/}
            <EditButton />
        </Datagrid>
    </List>
);
