import React from 'react';
import { DateInput, Edit, SimpleForm, TextInput, FormDataConsumer} from 'react-admin';
import { Typography } from '@material-ui/core';

export const BookTypeEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="name"/>
            <TextInput source="thumbnail" />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.thumbnail) {
                        return <img src={formData.thumbnail} style={{
                            margin: 10,
                            width: 260,
                            height: 260,
                        }} alt="Thumbnail"/>;
                    } else {
                        return <div/>;
                    }
                }}
            </FormDataConsumer>
            <TextInput source="fa_class" label="icon"/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.fa_class) {
                        return <img src={formData.fa_class} style={{
                            margin: 10,
                            width: 60,
                            height: 60,
                        }} alt="Icon"/>;
                    } else {
                        return <div/>;
                    }
                }}
            </FormDataConsumer>
            {/*<TextInput source="type_fields" />*/}
            <Typography color='error' variant='h4'>DELETE ITEM WITH CHANGE THIS FIELD TO CURRENT
                DATE</Typography>
            <DateInput source="deletedAt"/>

        </SimpleForm>
    </Edit>
);
