import React from 'react';
import {
    Create,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    FormDataConsumer,
    TextInput,
    SimpleForm
} from 'react-admin';

export const TransactionCreate = props => (
    <Create  {...props}>
        <SimpleForm>
            <ReferenceInput
                source="__read_to"
                label="user email"
                reference="global_users"
                filterToQuery={searchText => ({ email: {_ilike: `%${searchText}%`}})}
            >
                <AutocompleteInput optionText="email" focusInputOnSuggestionClick={true}/>
            </ReferenceInput>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    console.log(formData.__read_to)
                    const toAccount = formData.__read_to;
                  if(toAccount)return <ReferenceInput
                      source="to"
                      reference="global_wallet_accounts"
                      filter={{ foreign_owner_id: {_eq: toAccount}} }
                  >
                      <SelectInput optionText="foreign_owner_id"/>
                  </ReferenceInput>
                    else return <div />
                }}
            </FormDataConsumer>
            <NumberInput source="value" label="amount"/>
            <RadioButtonGroupInput source="name" choices={[
                {
                    name: 'Top Up'
                },
            ]} optionText="name" optionValue="name" defaultValue="Top Up" />
            <RadioButtonGroupInput source="transaction_item_table" choices={[
                {
                    name: 'system'
                },
            ]} optionText="name" optionValue="name" defaultValue="system"/>

        </SimpleForm>
    </Create >
);
