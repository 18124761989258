import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    EditButton,
    ReferenceField,
    DateField,
    NumberField,
    ImageField
} from 'react-admin';

import CustomAvatar from '../CustomAvatar'
import { CourseCategoryFilter } from './course_category_filter'

export const CourseCategories = props => (
    <List filters={<CourseCategoryFilter />}{...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField source="parent" reference="academy_course_categories" allowEmpty={true}>
                <TextField source="name" />
            </ReferenceField>
            <ImageField source="thumbnail" />
            <TextField source="name" />
            <TextField source="code" />
            <TextField source="deletedAt" />
            <CustomAvatar source="fa_class" />
            <NumberField source="count_course" />
            <DateField source="last_modified" />
            <DateField source="date_added" />
            <EditButton />
        </Datagrid>
    </List>
);
