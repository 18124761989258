import React from 'react';
import { SimpleForm, TextInput, FormDataConsumer, Create} from 'react-admin';

export const EventTypeCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <TextInput source="thumbnail" />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.thumbnail) {
                        return <img src={formData.thumbnail} style={{
                            margin: 10,
                            width: 260,
                            height: 260,
                        }} alt="Thumbnail"/>;
                    } else {
                        return <div/>;
                    }
                }}
            </FormDataConsumer>
            <TextInput source="fa_class" label="icon"/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.fa_class) {
                        return <img src={formData.fa_class} style={{
                            margin: 10,
                            width: 60,
                            height: 60,
                        }} alt="Icon"/>;
                    } else {
                        return <div/>;
                    }
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);
