import { Filter, TextInput } from 'react-admin';
import React from 'react';

export const CourseCategoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name._ilike" alwaysOn parse={value => {
            if(value === "" || value == "%%"){
                return "";
            }else {
                const val = value.split("%")
                    .join("");
                return `%${val}%`
            }

        }} />
    </Filter>
);
