import React from 'react';
import {
    DateInput,
    Create,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    SelectInput,
    FormDataConsumer,
    SimpleForm,
    TextInput
} from 'react-admin';


export const CityCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput perPage={200} source="province" reference="global_provinces" sort={{ field: 'name', order: 'ASC' }} allowEmpty={true}>
                <SelectInput optionText="name"  /></ReferenceInput>
            <TextInput source="code" />
        </SimpleForm>
    </Create>
);
