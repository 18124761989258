import React from 'react';
import {
    TabbedShowLayout,
    Tab,
    TextField,
    ImageField,
    Show,
    DateField,
    SelectField,
    EditButton,
    CreateButton,
    ReferenceManyField,
    Datagrid,
    ReferenceField,
    EmailField,
    NumberField

} from 'react-admin'

import CardActions from '@material-ui/core/CardActions';
import CustomAvatar from '../CustomAvatar'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { PlacementsReference } from '../people/people_placement_reference';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};



const UserShowAction = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        <Button
            component={Link}
            to={{
                pathname: '/people_work_placements/create',
                id: data.id,
                state: {
                    record: data.email,
                    basePath,
                    resource,

                },
            }}>
            Create Placement
        </Button>
    </CardActions>
);

export const UserInformationTab = (props) => {
    return (
        <Tab label="User Information" {...props}>
            <CustomAvatar
                source="avatar"
            />
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="status" />
            <EmailField source="email" />
            <TextField source="roles" />
            <TextField source="latest_balance" />
        </Tab>
    )
}


export const UserShow = (props) => (
    <Show actions={<UserShowAction />} {...props}>
        <TabbedShowLayout>
            <UserInformationTab />
            <Tab label="Placements">
                <ReferenceManyField label="Placements" reference="people_work_placements" target="user" source="id" {...props}>
                    <PlacementsReference />
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
